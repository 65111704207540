<template>
  <div class="flex flex-col w-full lg:w-1/2 py-24 lg:pr-20">
    <div class="flex justify-between items-center w-full mb-16">
      <div class="w-128">
        Crypto Ninja
      </div>

      <AppButton :to="{ name: routeNames.login }">Sign In</AppButton>
    </div>

    <div class="w-full max-w-lg m-auto lg:mx-0">
      <Form
        :validation-schema="forgotPasswordEmailSchema"
        autocomplete="off"
        @submit="onSubmitForm"
      >
        <span class="text-14 text-blueGray-400">Forgot password</span>
        <h4 class="mb-24 text-24">
          Enter your email
        </h4>

        <AppInput
          id="email"
          v-model="forgotForm.email"
          type="email"
          name="email"
          placeholder="email@example.com"
          label="Email"
          autofocus
          required
        />

        <AppButton
          :loading="loading"
          variant="primary"
          type="submit"
          class="w-full"
        >
          Submit
        </AppButton>
      </Form>
    </div>
  </div>
</template>

<script >
import { defineComponent, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Form } from 'vee-validate'

import { forgotPasswordEmailSchema } from '@/core/schemas'
import { routeNames } from '@/router'
import { notification } from '@/core/helpers'
import { authService } from '@/services'

import AppInput from '@/components/stateless/AppInput.vue'
import AppButton from '@/components/stateless/AppButton.vue'

export default defineComponent({
  name: 'Forgot',

  components: { Form, AppButton, AppInput },

  setup () {
    const router = useRouter()

    const loading = ref(false)
    const forgotForm = reactive({
      email: null
    })

    const onSubmitForm = () => {
      loading.value = true

      authService.recover(forgotForm)
        .then(() => {
          notification({ type: 'success', title: 'Success!', message: 'Reset password successfully confirmed! Please, check your email.' })

          router.push({ name: routeNames.login })
        })
        .catch((err) => notification({ title: 'Reset password error', errorCode: err.error.code }))
        .finally(() => (loading.value = false))
    }

    return {
      loading,
      forgotForm,
      routeNames,
      forgotPasswordEmailSchema,
      onSubmitForm
    }
  }
})
</script>
